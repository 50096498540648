import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import LinkCard from '../../components/LinkCard'
import GridWrapper from "../../components/GridWrapper"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import Image from "../../components/Image";
import styles from './styles.module.scss'

const CategoryPage = ({ data }) => {
  const {
    categoryPage: page,
    allDegreePage: relatedSections,
    allStudyGuidePage: studyGuidePages
  } = data;

  return (
    <Layout>
      <SEO title={page.metaTags.title} description={page.metaTags.description} />
      
      <CustomBreadcrumbs
          section={data.categoryPage.sections[0].section}
          path={page.path}
          title={page.title}
      />

      <h1 className={styles.pageTitle}>{page.title}</h1>

      { !!page.image && <Image imgName={page.image} /> }

      <section className={styles.pageIntro} dangerouslySetInnerHTML={{__html: page.intro}}/>

      {relatedSections.edges.length > 0 && page.name ?
        <>
          <h2 className={styles.relatedSectionsTitle}>{page.name} Degree Programs</h2>

          <GridWrapper columns={2}>
            {relatedSections.edges.map( (edge, index) => (
              <LinkCard
                title={edge.node.name}
                subtitle={edge.node.title}
                link={edge.node.path}
                key={index}
              />
            ))}
          </GridWrapper>
        </> : ''
      }
      
      <section className={styles.pageBody} dangerouslySetInnerHTML={{__html: page.body}}/>

      <div className={styles.faqSection}>
        {!!page.faq && page.faq.map( (item, index) => (
          <div className={styles.faqItem} dangerouslySetInnerHTML={{__html: item.faq}} key={index}/>
        ))}
      </div>

      {page.sections[0].section === 'study-guide' &&
        <section className={styles.relatedStudyGuidePages}>
          <GridWrapper columns={2}>
          {
            studyGuidePages.edges.map( (edge, index) => {
              // Return only studyGuide pages that are related to current section.
              if (!!edge.node.sections && edge.node.sections[0].section === page.path) {
                return (
                  <LinkCard
                      subtitle={edge.node.title}
                      link={edge.node.path}
                      key={index}
                  />
                )
              }
              else return null;
            })
          }
        </GridWrapper>
      </section>
      }

    </Layout>
  )
};

CategoryPage.propTypes = {
  data: PropTypes.shape({
    categoryPage: PropTypes.object,
    allDegreePage: PropTypes.object
  })
};

export default CategoryPage

export const pageQuery = graphql`
  query($id: String!) {
    categoryPage(nid: {eq: $id}) {
      metaTags {
        title
        description
      }
      name
      title
      intro
      body
      image
      path
      faq {
        faq
      }
      sections {
          section
      }
    }
    allDegreePage(filter: {categoryPages: {elemMatch: {categoryPage: {eq: $id}}}, isPublished: {ne: false}}, sort: {fields: name, order: ASC}) {
        edges {
            node {
                name
                title
                path
            }
        }
    }
    allStudyGuidePage {
        edges {
            node {
                path
                title
                sections {
                    section
                }
            }
        }
    }
  }
`;
