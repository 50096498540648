import React from "react"
import { Link, withPrefix } from 'gatsby'
import styles from './styles.module.scss'
import arrowSrc from '../../assets/img/arrow-right.svg'

const LinkCard = ({ title, subtitle, link }) => {
  
  return (
    <div className={styles.linkCardWrapper}>
      <Link to={withPrefix(link)} className={styles.linkCard}>
        {!!title && <h3 className={styles.title}>{title}</h3>}
        {!!subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
        <img src={arrowSrc} className={styles.arrow} alt="arrow"/>
      </Link>
    </div>
  )
};

export default LinkCard
